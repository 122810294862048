import React from "react";
import { IParticipantLookupResponse, IParticipantSearchData } from "models/Participant.model";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { MenuItem, Paper, Select } from "@material-ui/core";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import OutlinedInput from "../OutlinedInput/OutlinedInput";
import trim from "lodash/trim";
import debounce from "lodash/debounce";
import { serviceContainer } from "services/serviceContainer";
import { PersonSearchLibraries } from "services/person-search/PersonSearchService.types";

export type PersonSearchResult = {
  identifier: any;
  personDetails: IParticipantLookupResponse;
  searchLibrary: PersonSearchLibraries;
};

type PersonSearchProps = {
  availableSearchLibraries: PersonSearchLibraries[];
  initialSearchLibrary: PersonSearchLibraries;
  onSearchCompleted: (searchResult: PersonSearchResult) => void;
};

export enum PersonSearchDisplayNames {
  ContactLibrary = "Contact Library",
  LBP = "LBP Register",
}

const PersonSearch: React.FC<PersonSearchProps> = ({
  availableSearchLibraries,
  initialSearchLibrary,
  onSearchCompleted,
}) => {
  const { t } = useTranslation();

  const [isOptionListOpen, setIsOptionListOpen] = React.useState(false);

  const [selectedSearchLibrary, setSelectedSearchLibrary] = React.useState(initialSearchLibrary);
  const [personSearchInputValue, setPersonSearchInputValue] = React.useState("");

  const [personOptions, setPersonOptions] = React.useState<IParticipantSearchData[]>([]);
  const isAutocompleteInputFocusedRef = React.useRef(false);
  const debouncedSearch = React.useMemo(
    () =>
      debounce(async (keywords: string) => {
        try {
          const options = await serviceContainer.cradle.personSearchService.search({
            library: selectedSearchLibrary,
            keywords,
          });

          // If autocomplete input is not focused anymore, we drop the result
          if (!isAutocompleteInputFocusedRef.current) {
            return;
          }

          setPersonOptions(options);
          setIsOptionListOpen(true);
        } catch (e) {
          serviceContainer.cradle.logger.error(e);
        }
      }, 1000),
    [selectedSearchLibrary]
  );

  const debouncedLookupPerson = React.useCallback((keywords: string) => debouncedSearch(keywords), [debouncedSearch]);
  const handlePersonSearchInputChange = React.useCallback(
    async (event: any, value: string, reason: string) => {
      setPersonSearchInputValue(value);

      if (!value || value.length < 2 || reason === "reset") {
        return;
      }

      await debouncedLookupPerson(value);
    },
    [debouncedLookupPerson]
  );

  const handlePersonSearchValueChange = React.useCallback(
    async (event: any, value: any, reason: string) => {
      setIsOptionListOpen(false);

      if (!value) {
        return;
      }
      const personDetails = await serviceContainer.cradle.personSearchService.find({
        library: selectedSearchLibrary,
        identifier: value.identifier,
      });

      onSearchCompleted({
        personDetails,
        identifier: value.identifier,
        searchLibrary: selectedSearchLibrary,
      });
    },
    [selectedSearchLibrary, onSearchCompleted]
  );

  const handlePersonSearchInputBlur = React.useCallback(
    (evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setIsOptionListOpen(false);

      // If input value is empty, clear the address details
      if (!trim(evt.target.value)) {
        return;
      }
    },
    []
  );

  const handleSelectionChanged = React.useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    switch (event.target.value) {
      case PersonSearchLibraries.ContactLibrary:
        setSelectedSearchLibrary(PersonSearchLibraries.ContactLibrary);
        break;
      case PersonSearchLibraries.LBP:
        setSelectedSearchLibrary(PersonSearchLibraries.LBP);
        break;
    }
  }, []);

  const renderInput = React.useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <div ref={params.InputProps.ref}>
          <OutlinedInput
            {...params.inputProps}
            fullWidth={true}
            type="search"
            autoComplete="off"
            onBlur={(evt) => {
              isAutocompleteInputFocusedRef.current = false;
              const inputProps: any = params.inputProps;
              if (inputProps.onBlur) {
                inputProps.onBlur(evt);
              }

              handlePersonSearchInputBlur(evt);
            }}
            onFocus={(evt) => {
              isAutocompleteInputFocusedRef.current = true;
              const inputProps: any = params.inputProps;
              if (inputProps.onFocus) {
                inputProps.onFocus(evt);
              }
            }}
            error={false}
          />
        </div>
      );
    },
    [handlePersonSearchInputBlur]
  );

  const getDisplayNameForLibrary = React.useCallback(
    (library: PersonSearchLibraries) => {
      switch (library) {
        case PersonSearchLibraries.ContactLibrary:
          return t(PersonSearchDisplayNames.ContactLibrary);
        case PersonSearchLibraries.LBP:
          return t(PersonSearchDisplayNames.LBP);
      }
    },
    [t]
  );

  return (
    <StyledSearchContainer data-testid={"PersonSearch"}>
      <StyledSearchTitle>{t(`Search for a person (optional)`)}</StyledSearchTitle>
      {t(`Populate details for the participant from an existing contact or from a linked registration scheme`)}
      <StyledSearchInput>
        <StyledSelect variant={"outlined"} onChange={handleSelectionChanged} value={selectedSearchLibrary}>
          {availableSearchLibraries.map((library) => (
            <MenuItem value={library} key={library}>
              {getDisplayNameForLibrary(library)}
            </MenuItem>
          ))}
        </StyledSelect>
        <Autocomplete
          data-testid="PersonSearchAutocomplete"
          freeSolo={false}
          openOnFocus={false}
          selectOnFocus={false}
          blurOnSelect={true}
          onChange={handlePersonSearchValueChange}
          onInputChange={handlePersonSearchInputChange}
          inputValue={personSearchInputValue}
          open={isOptionListOpen}
          PaperComponent={StyledAutocompletePopupPaper}
          ListboxComponent={StyledAutocompleteListbox}
          style={{
            width: "60%",
          }}
          renderInput={renderInput}
          options={personOptions}
          getOptionLabel={(option) => {
            if (selectedSearchLibrary === PersonSearchLibraries.LBP) {
              return `${option.name.trim()} (${option.identifier})`;
            }
            return option.name.trim();
          }}
          getOptionSelected={(option, value) => {
            return option.identifier === value.identifier;
          }}
        />
      </StyledSearchInput>
    </StyledSearchContainer>
  );
};

const StyledAutocompletePopupPaper = styled(Paper)(
  ({ theme }) => css`
    box-shadow: ${theme.shadows[6]};
  `
);

const StyledAutocompleteListbox = styled.ul(
  ({ theme }) => css`
    & > li {
      border-left: 4px solid transparent;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    & > li:hover {
      border-left: 4px solid ${theme.palette.primary.main};
      background: ${theme.palette.objective.blue.light};
    }
  `
);

const StyledSelect = styled(Select)(
  ({ theme }) => css`
    width: 40%;
    border-radius: 4px 0 0 4px;
  `
);

const StyledSearchInput = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.objective.light.white};
    display: flex;
    flex-direction: row;
    ${theme.mixins.flexGap("0")}
    color: ${theme.palette.objective.dark.night};
    border-radius:  8px 8px 4px 4px;
  `
);

const StyledSearchTitle = styled.span(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 16px;
  `
);

const StyledSearchContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.objective.light.day};
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("8px")}
    padding: 16px;
    color: ${theme.palette.objective.dark.night};
  `
);

export default PersonSearch;
