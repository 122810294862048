import React from "react";
import FormikTextInput from "../FormikTextInput/FormikTextInput";
import { QualificationFormField } from "./models/QualificationFormField";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IQualificationEntity } from "../../models/Qualification.model";
import { formValidationUtils } from "../../utils/formValidationUtils";
import { useStoreSelector } from "../../store/hooks";
import { selectParticipantEntitiesByApplicationId } from "../../store/domain-data/participant/participant";
import { selectCurrentApplicationId } from "../../store/app-state/application-navigation/applicationNavigation";

type ParticipantQualificationLicenseIdInputProps = {
  isRequired: boolean;
};

const ParticipantQualificationLicenseIdInput: React.FC<ParticipantQualificationLicenseIdInputProps> = ({
  isRequired,
}) => {
  const { t } = useTranslation();
  const { values, setFieldError, errors } = useFormikContext<IQualificationEntity>();
  const applicationId = useStoreSelector(selectCurrentApplicationId);
  const currentInputValue = values[QualificationFormField.QualificationNumber];
  const participants = useStoreSelector((state) => selectParticipantEntitiesByApplicationId(state, applicationId));

  React.useEffect(() => {
    const qualificationNumbers = participants.flatMap((participant) =>
      participant.qualifications.map((qualification) => qualification.qualificationNumber)
    );
    const isDuplicated = qualificationNumbers.includes(currentInputValue);
    const fieldError = errors[QualificationFormField.QualificationNumber];

    if (isDuplicated && !fieldError) {
      setFieldError(
        QualificationFormField.QualificationNumber,
        formValidationUtils.getErrorMessageForDuplicateLicenseIds()
      );
    }
  }, [applicationId, currentInputValue, errors, participants, setFieldError]);

  return (
    <FormikTextInput
      label={t(`License / Certification Number or ID`)}
      required={isRequired}
      fieldName={QualificationFormField.QualificationNumber}
    />
  );
};

export default ParticipantQualificationLicenseIdInput;
