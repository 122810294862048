import React from "react";
import ParticipantQualificationLicenseIdInput from "components/ParticipantEditDialog/ParticipantQualificationLicenseIdInput";
import FlexBox from "components/FlexBox/FlexBox";
import { useStoreSelector } from "store/hooks";
import { selectLicensingClassesByQualificationName } from "store/domain-data/qualification-type/qualificationType";
import { useFormikContext } from "formik";
import { IQualificationEntity } from "models/Qualification.model";
import LbpLicenseClassTag from "components/ParticipantEditDialog/qualification-forms/LbpLicenseClassTag";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

type LbpQualificationFormProps = {};

const LbpQualificationForm: React.FC<LbpQualificationFormProps> = () => {
  const { values } = useFormikContext<IQualificationEntity>();
  const { t } = useTranslation();

  const licensingClasses = useStoreSelector((state) =>
    selectLicensingClassesByQualificationName(state, values.qualificationName)
  );

  return (
    <FlexBox direction={"column"} spacing={4}>
      <ParticipantQualificationLicenseIdInput isRequired={true} />
      <StyledInputLabel>
        {t(`License Classes`)}
        <StyledAsterisk>*</StyledAsterisk>
      </StyledInputLabel>
      <FlexBox direction={"row"} spacing={2} flexWrap={"wrap"}>
        {licensingClasses.map((licenseClass) => (
          <LbpLicenseClassTag key={licenseClass.id} displayName={licenseClass.displayName} name={licenseClass.name} />
        ))}
      </FlexBox>
    </FlexBox>
  );
};

const StyledInputLabel = styled.div(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 400;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledAsterisk = styled.span(
  ({ theme }) => css`
    font-size: 16px;
    padding-left: 4px;
    color: ${theme.palette.objective.red.main};
  `
);

export default LbpQualificationForm;
