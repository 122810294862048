import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import ParticipantQualificationLicenseIdInput from "components/ParticipantEditDialog/ParticipantQualificationLicenseIdInput";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import { useTranslation } from "react-i18next";

type OtherQualificationFormProps = {};

const OtherQualificationForm: React.FC<OtherQualificationFormProps> = () => {
  const { t } = useTranslation();

  return (
    <FlexBox direction={"column"} spacing={4} flexGrow={1}>
      <FormikTextInput label={t("Qualification Name")} required={true} fieldName={"otherQualificationType"} />
      <ParticipantQualificationLicenseIdInput isRequired={false} />
    </FlexBox>
  );
};

export default OtherQualificationForm;
