import React from "react";
import styled, { css } from "styled-components/macro";
import { IParticipantGroupData } from "models/Participant.model";
import { Form, Formik, FormikHelpers } from "formik";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  selectOwnerGroupFormFields,
  setOwnerGroupFormFields,
} from "store/app-state/participant-buffer/participantBuffer";
import * as yup from "yup";
import TextButton from "components/odl-v2/Button/TextButton";
import OwnerGroupFormContent from "components/ParticipantEditDialog/owner-group-form/OwnerGroupFormContent";

type OwnerGroupFormProps = {
  onCloseOwnerGroupForm: () => void;
};

const OwnerGroupForm: React.FC<OwnerGroupFormProps> = ({ onCloseOwnerGroupForm }) => {
  const dispatch = useStoreDispatch();
  const currentOwnerGroups = useStoreSelector((state) => selectOwnerGroupFormFields(state));

  const ownerGroupFormSchema = React.useMemo(() => {
    return yup.object().shape({
      groupName: yup.string(),
      groupList: yup.string(),
    });
  }, []);

  const handleSubmit = React.useCallback(
    async (formValues: IParticipantGroupData, formikHelpers: FormikHelpers<IParticipantGroupData>) => {
      dispatch(setOwnerGroupFormFields(formValues));
    },
    [dispatch]
  );

  return (
    <React.Fragment>
      <Formik<IParticipantGroupData>
        initialValues={currentOwnerGroups}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={ownerGroupFormSchema}
        validateOnChange={true}
      >
        {() => (
          <Form>
            <OwnerGroupFormContent />
          </Form>
        )}
      </Formik>

      <StyledTextButton onClick={onCloseOwnerGroupForm}>Cancel</StyledTextButton>
    </React.Fragment>
  );
};

const StyledTextButton = styled(TextButton)(
  ({ theme }) => css`
    justify-content: start;
    color: ${theme.palette.objective.blue.main};
    width: 200px;
    height: 32px;
    padding: 6px 12px 6px 0;
  `
);

export default OwnerGroupForm;
