import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import TextButton from "components/odl-v2/Button/TextButton";
import OwnerGroupForm from "components/ParticipantEditDialog/owner-group-form/OwnerGroupForm";
import Guard from "components/Guard/Guard";
import {
  selectOwnerGroupFormFields,
  setOwnerGroupFormFields,
} from "store/app-state/participant-buffer/participantBuffer";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import isEmpty from "lodash/isEmpty";
import every from "lodash/every";
type OwnerGroupDetailsProps = {};

const OwnerGroupDetails: React.FC<OwnerGroupDetailsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const currentOwnerGroups = useStoreSelector((state) => selectOwnerGroupFormFields(state));

  const shouldInitOwnerGroupForm = React.useMemo(() => {
    return !every(currentOwnerGroups, isEmpty);
  }, [currentOwnerGroups]);

  const [showOwnerGroupForm, setShowOwnerGroupForm] = React.useState(shouldInitOwnerGroupForm);

  const handleCloseOwnerGroupForm = React.useCallback(() => {
    dispatch(setOwnerGroupFormFields({ groupList: "", groupName: "" }));
    setShowOwnerGroupForm(false);
  }, [dispatch]);

  return (
    <FlexBox spacing={2} direction={"column"} paddingX={14} paddingY={6} data-testid="OwnerGroupDetails">
      <StyledTitle>{t("Group of owners")}</StyledTitle>
      <StyledInformation>
        {t(`Registered Owner is the organisation or individual listed as the Registered Owner on the Record of Title`)}
      </StyledInformation>
      <StyledInformation>{t(`If there is more than one legal owner you can:`)}</StyledInformation>
      <StyledOrderedList>
        <StyledListItem>
          {t(`add each owner as a separate participant and give each the`)} <strong>{t(`Additional owner`)}</strong>{" "}
          {t(`role`)}
        </StyledListItem>
        <StyledListItem>
          <div>{t(`enter a group of owners below, listing the names of each owner`)}</div>
          <div>
            {t(
              ` Note: the contact person you added on the previous step is automatically shown as the primary owner for the group`
            )}
          </div>
        </StyledListItem>
      </StyledOrderedList>
      <Guard condition={!showOwnerGroupForm}>
        <StyledTextButton onClick={() => setShowOwnerGroupForm(true)}>{t(`Enter group of owners`)}</StyledTextButton>
      </Guard>
      <Guard condition={showOwnerGroupForm}>
        <OwnerGroupForm onCloseOwnerGroupForm={handleCloseOwnerGroupForm} />
      </Guard>
    </FlexBox>
  );
};

const StyledTextButton = styled(TextButton)(
  ({ theme }) => css`
    justify-content: start;
  `
);
const StyledTitle = styled.div(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    color: ${theme.palette.objective.dark.fiordland};
  `
);
const StyledInformation = styled.div(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.night};
  `
);
const StyledOrderedList = styled.ul(
  ({ theme }) => css`
    margin: 0;
  `
);

const StyledListItem = styled.li(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.night};
  `
);

export default OwnerGroupDetails;
